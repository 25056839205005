.page-title-container {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: #E3EBF6 1px solid;
}

.page-title {
  font-size: 1.75rem;
  margin-bottom: 0;
}

.page-pretitle {
  font-size: .625rem;
  text-transform: uppercase;
  letter-spacing: .08rem;
  color: #95aac9;
  margin: 0;
}

.page-title-container-no-bottom {
  margin-bottom: 0;
  border-bottom: none;
}
