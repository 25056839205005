$animationDuration: 500ms;

/* initial-load-background */
.initial-load-background-enter {
  opacity: 0;
}
.initial-load-background-enter-active {
  opacity: 1;
  transition: opacity $animationDuration;
}
.initial-load-background-exit {
  opacity: 1;
}
.initial-load-background-exit-active {
  opacity: 0;
  transition: opacity $animationDuration;
}

/* initial-load-logo */
.initial-load-logo,
.initial-load-logo-enter {
  opacity: 0;
}
.initial-load-logo-enter-active {
  opacity: 1;
  transition: opacity $animationDuration;
}
.initial-load-logo-entered,
.initial-load-logo-exit {
  opacity: 1;
}
.initial-load-logo-exit-active {
  opacity: 0;
  transition: opacity $animationDuration;
}
