.login-background {
  background-image: linear-gradient(to bottom right, rgba(6, 161, 243, 0.9), rgba(132, 42, 205, 0.9)), url(./../img/sidebar-background.jpg);
  background-size: cover;
  background-position: center;
}

.login-column {
  min-height: 100vh;

  margin-left: 20px;
  margin-right: 20px;
  padding: 40px;
  padding-top: 65px;

  @media (min-width: 576px) {
    max-width: 350px;
    margin-left: 50px;
  }

  background: white;

  -webkit-box-shadow: 0 0 8px 0 rgba(69,69,69,0.6);
  -moz-box-shadow: 0 0 8px 0 rgba(69,69,69,0.6);
  box-shadow: 0 0 8px 0 rgba(69,69,69,0.6);

  .login-logo {
    //max-width: 100%;
    width: 100%;
    margin-bottom: 65px;
    .sidebar-logo-path {
      opacity: 1;
      transition: opacity .25s;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .login-column-footer {
    position: absolute;
    bottom: 0;
    padding-bottom: 15px;
    a {
      color: #959595;
      text-decoration: underline;
      font-size: 0.8rem;
    }
  }
}

.login-footer {
  display: none;
  @media (min-width: 576px) {
    display: block;
  }
  position: fixed;
  right: 0;
  bottom: 0;

  padding: 15px 20px;

  opacity: 0.75;
  font-size: 0.8rem;
  color: white;
  text-align: right;
  a {
    color: #E7E7E7;
    text-decoration: underline;
  }
}
