$primary: rgba(6, 161, 243, 0.9);
//$secondary: rgba(132, 42, 205, 0.9));

//$primary: rgba(6, 161, 243, 0.9);
//$secondary: rgba(132, 42, 205, 0.9);

//$primary: rgba(12, 122, 219, 0.9);
$secondary: #6E10B3E5;
$secondary: rgba(110, 16, 179, 0.9);

$active-color: $primary;

.st0 {
  fill: $primary;
}
.st1 {
  fill: $secondary;
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~@bootstrap-extended-style/bootstrap-extended-style/index";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

@import "login";
@import "sidebar";
@import "tabbar";
@import "table";
@import "title";
@import "transition";

.card-comment {
  margin-bottom: 15px;
}

.loading-row {
  background-color: transparent !important;
}

.clickable {
  cursor: pointer;
  webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.detail-header-cell {
  text-align: right;
  width: 250px;
}

.detail-header-cell-sm {
  text-align: right;
  width: 250px;
}

.page-container {
  margin-bottom: 200px;
}

/* Redesign */

body {
  position: relative; // Required for tooltips to function correctly.
  background-color: #F9FBFD;
  @media print {
    background-color: transparent;
  }
}

.navbar-only {
  box-shadow: none;
  background-color: #FFFFFF !important;
  border-bottom: #E3EBF6 1px solid;
}

.table .thead-light th {
  background-color: transparent;
  border-top: 1px solid #E3EBF6;
  border-bottom: 1px solid #E3EBF6;
}

.table thead th {
  padding: 1rem;
}
.table td {
  padding: .75rem;
}
.table td, .table th {
  border-color: #E3EBF6;
}
.table-borderless td, .table-borderless th {
  border-top: none;
}

.badge-pill {
  padding: .3rem .6rem;
}

.card {
  border: 1px solid #E3EBF6;
}
.card-footer {
  border-top: 1px solid #E3EBF6;
  background-color: #F9FBFD;
}

.dropdown-menu {
  border: 1px solid #E3EBF6;
}
.dropdown-menu-navbar {
  margin-top: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-control {
  border-color: #E3EBF6;
}

hr {
  border-color: #E3EBF6;
}

.page-container {
  margin-left: 250px;
  padding-top: 80px;
  padding-left: 45px;
  padding-right: 45px;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}


.card-arrow-left {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 20px 40px 0;
  border-color:
          transparent #fff transparent
          transparent;
  float: left;
}
.card-arrow-right {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 0 40px 20px;
  border-color:
          transparent transparent
          transparent #fff;
  float: right;
}
.left-border-0 {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.right-border-0 {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn:disabled {
  cursor: not-allowed;
}

.h-100vh {
  height: 100vh;
}
