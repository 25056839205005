.table .thead-light th {
  background-color: transparent;
  border-top: 1px solid #E3EBF6;
  border-bottom: 1px solid #E3EBF6;
}

.table thead th {
  padding: 1rem;
}
.table td {
  padding: .75rem;
}
.table td, .table th {
  border-color: #E3EBF6;
}
.table-borderless td, .table-borderless th {
  border-top: none;
}

tr.table-clickable-row {
  th, td {
    padding: 0;
    a {
      text-decoration: none;
      color: #212529;
      display: block;
      padding: 12px;
    }
  }
}

.td-content-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 56px;
}
.th-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 56px;
  background-color: #F9FBFD !important;
  z-index: 5;

  // Workaround for border not working for sticky elements:
  box-shadow: 0 1px #E3EBF6;
}
tr.tr-sticky {
  th {
    @extend .th-sticky;
  }
}
